



























import { Component } from 'vue-property-decorator'
import { OverlayView } from '@/views/mixins/OverlayView'
import DeviceOption from '@/components/DeviceOption.vue'
import BackButton from '@/components/BackButton.vue'
import Fact from '@/components/facts/Fact.vue'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import constants from '@/constants'
import { saleJourneyActionHandler, saleJourneyRouteGuard } from '@/services/SaleJourneyService'

@Component({
  components: {
    DeviceOption,
    BackButton,
    Fact
  }
})
export default class Device extends OverlayView {
  constructor () {
    super('coverage')
  }

  created () {
    this.doCreated()
  }

  onFactChange (factChange: FactChangeEvent) {
    this.setFactValue(factChange)
    if (factChange.next) {
      this.$router.push({ name: constants.routeNames.APPLICATION_PLAN })
    }
  }

  mounted() {
    // Sale Journey Route Guard - to handle back button state
    saleJourneyRouteGuard(saleJourneyActionHandler(this.$store.state.product.saleJourneyState), this.$router, this.$store.state.product.invoiceId)
  }
}
