









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class BackDashbboard extends Vue {
  @Prop()
  routeName!: string;

  private clicked = false

  back () {
    this.clicked = true
    this.$router.replace({ name: this.routeName })
  }
}
